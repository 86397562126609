.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.svg {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* transition: opacity 150ms ease;
  opacity: 1; */
  display: block;
}

.moving {
  display: none;
}

.land {
  stroke: yellow;
  fill: none;
}

circle {
  stroke: yellow;
  fill: yellow;
  fill-opacity: 0.4;
}

.highlightedArea {
  stroke: white;
  fill: yellow;
  fill-opacity: 0.4;
}
